import React from "react"

const errorPage = () => {
  return (
    <div className='tc w-100 pa5'>
      <img className='dib' width="500px" alt='404 meme' src="https://i.imgflip.com/4fico7.jpg" />
    </div>
  )
}

export default errorPage
